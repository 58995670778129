.headerDividerContainer {
  width: 100%;
  height: 40px;
  background-color: #ff9900;
  position: relative;
  overflow: hidden;
  margin-bottom: 2px;
}

.headerDividerBox {
  position: absolute;
  height: 40px;
  width: 270px;
  background-color: #ffffff;
  bottom: -30px;
  transform: rotate(-50deg);
  right: -100px;
}

.llsLogoImage {
}

.headerDiv {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #ffff;
}

@media only screen and (max-width: 600px) {
  .headerDividerContainer {
    display: none;
  }
  .llsLogoImage {
    width: 100px;
  }
  .signInBtn {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
    font-size: small;
  }
}

//small & medium screen rules
@media only screen and (min-width: 600px) {
  .headerDividerContainer {
    height: 20px;
  }
  .llsLogoImage {
    width: 150px;
  }
}

//large screen rules
@media only screen and (min-width: 700px) {
  .headerDividerContainer {
    height: 25px;
  }
  .llsLogoImage {
    width: 200px;
  }
}

//X-large screen rules
@media only screen and (min-width: 1000px) {
  .headerDividerContainer {
    height: 30px;
  }
  .llsLogoImage {
    width: 250px;
  }
}
